import React from 'react';

interface PreloaderProps {
  small?: boolean;
}

const preloader: React.FC<PreloaderProps> = ({ small }) => {
  return (
    <div
      id="page-preloader"
      className="flex items-center justify-center h-20 bg-transparent z-20 opacity-[1] visible relative"
    >
      <div
        className={`loader ${
          !small ? `w-9 h-9` : 'w-3 h-3'
        } border-[6px] border-black border-solid rounded-[50%] border-t-[#707a4e] absolute left-1/2 top-1/2 translate-x-[150%] translate-y-[-50%]`}
      ></div>
    </div>
  );
};

export default preloader;
