import React, { useEffect, useState } from "react";
import Container from "../components/container";
import Title from "../components/title";
import useCart from "../context/cart.context";
import { formatMoney } from "../utils";
import { TCart, TCartItem } from "../types/types";
import CheckModal from "../components/modal";
import UpsellProductRow from "../components/cart/UpsellProductRow";
import ProductShippingAddress from "../components/cart/ProductShippingAddress";
import EmptyCart from "../components/cart/EmptyCart";
import Summary from "../components/cart/Summary";
import RemoveButton from "../components/cart/RemoveButton";
import SmallPreloader from "../components/SmallPreloader";
import OldProductAdditions from "../components/cart/OldProductAdditions";
import { gql } from "@apollo/client";
import CartProductItem from "../components/cart/CartProductItem";
import { client } from "../context/apollo.context";
// import { ChangeAddress } from "../components/cart/ChangeAddress";

const que = gql`
  query Product($slug: ID!) {
    product(id: $slug, idType: SLUG) {
      heroImage {
        heroImage {
          altText
          sourceUrl
        }
      }
      id
      name
      slug
      description
      shortDescription
      attributes {
        nodes {
          name
          options
        }
      }
      metaData {
        key
        value
      }
      image {
        sourceUrl
      }
      ... on BundleProduct {
        id
        databaseId
        name
        price
        bundleItems {
          edges {
            node {
              id
              databaseId
              image {
                sourceUrl
              }
              name
              slug
            }
            quantityMax
            quantityMin
            bundledItemId
          }
        }
      }
    }
  }
`;

export default function Cart(): JSX.Element {
  const { cart, removeVoucherCode, updateCart, shippingCost } = useCart();
  const [checkIsOpen, setCheckIsOpen] = useState<boolean>(false);
  const [isSummaryLoading, setSummaryLoading] = useState<boolean>(false);

  const [data, setData] = useState<any>(null);
  const ref = React.createRef<HTMLButtonElement>();

  const getParentKey = (product: any) => {
    return product?.extraData?.find(
      (metaItem: { key: string; value: string }) => metaItem.key === "parent"
    )?.value;
  };

  // main products from cart (without upsell)
  const getMainProducts: (cart: TCart) => TCartItem[] = (cartMainProducts) =>
    cartMainProducts.products
      ?.filter((p) => p.product.node !== null)
      .filter((p) => p.product.node.name !== "Dummy")
      .filter((p) => p.product.node.__typename === "BundleProduct");

  const mainProducts = getMainProducts(cart);

  // products with same parent key
  const getAdditionalProducts: (cart: TCart, product) => TCartItem[] = (
    cartData,
    product
  ) => {
    // console.log(
    //   cartData.products
    //     .map((p) => {
    //       console.log("compare", p.product.node.name, product);
    //     })
    // );
    return cartData.products
      .filter(
        (cartProduct) =>
          getParentKey(
            cartProduct?.product ? cartProduct.product : cartProduct
          ) === getParentKey(product?.product ? product.product : product)
      )
      .filter((p) => p.product.node.__typename === "SimpleProduct");
  };

  // get all additionals products with quantity
  const getUpsellProducts = (product: any, additionals: any) =>
    product.product.node.bundleItems.nodes.map((upsellProduct: any) => {
      const qty = additionals.find((additionalProduct: any) => {
        return (
          upsellProduct.databaseId === additionalProduct.product.node.databaseId
        );
      });
      console.log("additionals", additionals);
      return { ...upsellProduct, key: qty?.key, quantity: qty?.quantity };
    });
  const getDataForMainProduct = async (product: any) => {
    const { data } = await client.query({
      query: que,
      variables: { slug: product.product.node.slug },
    });
    return data;
  };
  // const increaseProductQuantity = (
  //   e: React.FormEvent<HTMLSelectElement>,
  //   product: TCartItem
  // ) => {
  //   const qty = +e.currentTarget.value;
  //   qty === 0 && mainProducts.length === 1 ? emptyCart() : updateItem(product.key, qty);
  // };
  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <Container noHero>
      <CheckModal
        open={checkIsOpen}
        onClose={() => setCheckIsOpen(false)}
        pCancelButtonRef={ref}
      />
      <div className="mx-auto mt-12 max-w-7xl">
        <Title title="Warenkorb" breadcrumb="" subtitle="" />
        <form
          action="/checkout"
          className="mt-12 lg:grid lg:grid-cols-12 lg:gap-x-12 lg:items-start xl:gap-x-16"
        >
          <section aria-labelledby="cart-heading" className="lg:col-span-7">
            <h2 id="cart-heading" className="sr-only">
              Items in your shopping cart
            </h2>
            <ul className="border-b-2 border-black">
              {mainProducts.reverse().map((product, productIdx) => {
                return (
                  <CartProductItem
                    product={product}
                    productIdx={productIdx}
                    setSummaryLoading={setSummaryLoading}
                    isSummaryLoading={isSummaryLoading}
                    cart={cart}
                    helpers={{
                      getAdditionalProducts,
                      getUpsellProducts,
                      getParentKey,

                    }}
                  />
                );
              })}
            </ul>
            <EmptyCart cart={cart} />
          </section>
          <Summary
            removeVoucherCode={removeVoucherCode}
            updateCart={updateCart}
            cart={cart}
            setCheckIsOpen={setCheckIsOpen}
            setIsLoading={setSummaryLoading}
            isLoading={isSummaryLoading}
          />
        </form>
      </div>
    </Container>
  );
}
