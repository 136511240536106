import { makeUniqueId } from '@apollo/client/utilities';
import React, { useEffect, useState } from 'react';
import useCart from '../../context/cart.context';
import { formatMoney } from '../../utils';
import { gql, useMutation } from '@apollo/client';
import { ADD_BUNDLE_TO_CART } from '../../mutations';
import { client } from '../../context/apollo.context';

const que = gql`
  query Product($slug: ID!) {
    product(id: $slug, idType: SLUG) {
      heroImage {
        heroImage {
          altText
          sourceUrl
        }
      }
      id
      name
      slug
      description
      shortDescription
      attributes {
        nodes {
          name
          options
        }
      }
      metaData {
        key
        value
      }
      image {
        sourceUrl
      }
      ... on BundleProduct {
        id
        databaseId
        name
        price
        bundleItems {
          edges {
            node {
              id
              databaseId
              image {
                sourceUrl
              }
              name
              slug
            }
            quantityMax
            quantityMin
            bundledItemId
          }
        }
      }
    }
  }
`;

const UpsellProductRow = ({
  supp,
  parentKey,
  upKey,
  data,
  setIsLoading,
  isLoading,
  product,
  isSummaryLoading,
}) => {
  const { refetch, updateItem, removeCartItem, cart } = useCart();
  const [addBundleToCart] = useMutation(ADD_BUNDLE_TO_CART);
  // const [data, setData] = useState(null);
  const simpleProducts = cart.products?.filter(
    (p) => p.product.node.__typename === 'SimpleProduct'
  );
  const bundledProduct = simpleProducts.filter((additional) => {
    const bundledBy = additional.extraData.find(
      ({ key }) => key === 'bundled_by'
    )?.value;
    console.log(
      'bundledBy',
      bundledBy,
      product.extraData.find(({ key }) => key === 'bundled_items')?.value
    );
    return bundledBy == product.key;
  });
  const extraData = product.extraData.reduce((acc, item) => {
    acc[item.key] = item.value;
    return acc;
  }, {});

  const getBundleItem = (id: number) =>
    data &&
    data?.product?.bundleItems?.edges?.find((item) => {
      console.log('data', data);
      return item.node.databaseId === id;
    });
  useEffect(() => {
    console.log('isLoading', supp.slug);
  }, [supp]);

  return (
    <div
      className={`flex flex-row items-center w-full gap-5 py-1 ${
        isLoading ? 'animate-pulse' : 'animate-none'
      }`}
      key={makeUniqueId('')}
    >
      <select
        defaultValue={
          bundledProduct.find(
            (p) => p.product.node.databaseId === supp.databaseId
          )?.quantity || 0
        }
        disabled={isLoading || isSummaryLoading}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          const qty = +e.currentTarget.value;
          try {
            // if (upKey) {
            //   setIsLoading(true);
            //   return updateItem(upKey, qty).then(() => refetch()).then((data) => setIsLoading(false));
            // }
            // if (!upKey) {

            setIsLoading(true);
            removeCartItem([product.key]).then((data) => {
              const bundleItem = getBundleItem(supp.databaseId);
              // 1978(main) 21,22,23
              //   // 1995(main) 24,25,26
              //   // 1999(main) 27,28,29
              const checkRelated = (productId: number, id: number) => {
                const related = data?.product?.bundleItems?.edges?.find(
                  (item) => {
                    return item.node.databaseId === productId;
                  }
                );
                if (related?.node?.databaseId === id) {
                  return true;
                }
              };
              console.log(
                'checkRelated',
                checkRelated(product.product.node.databaseId, 22)
              );
              addBundleToCart({
                variables: {
                  items: [
                    {
                      productId: product?.product.node.databaseId,
                      quantity: 1,
                      extraData: JSON.stringify({
                        parent: extraData.parent,
                        couponCode: extraData.couponCode,
                        ShippingData: { ...JSON.parse(extraData.ShippingData) },
                        Note: extraData.Note,
                        Variant: extraData.Variant,
                        [extraData.voucherDelivery
                          ? 'voucherDelivery'
                          : 'testVoucherDelivery']: extraData.voucherDelivery
                          ? extraData.voucherDelivery
                          : '',
                        isCoupon: !!extraData.isCoupon,
                        [extraData?.voucherDeliveryMeta
                          ? 'voucherDeliveryMeta'
                          : 'testVoucherDeliveryMeta']: extraData?.voucherDeliveryMeta
                          ? extraData.voucherDeliveryMeta
                          : '',
                        pdf: extraData?.pdf ? extraData.pdf : '',
                        bbDeliveryPrice: extraData?.bbDeliveryPrice
                          ? extraData.bbDeliveryPrice
                          : '',
                        stamp: {
                          ...JSON.parse(extraData.stamp),
                          [bundleItem.bundledItemId]: {
                            discount: '',
                            // bundledItemId: bundleItem.bundledItemId,
                            // name: supp.name,
                            product_id: supp.databaseId,
                            quantity: qty,
                            // productId: supp.databaseId,
                            // optional_selected: "yes",
                          },
                        },
                      }),
                    },
                  ],
                },
              })
                .then((data) => {
                  refetch();
                })
                .then((data) => {
                  refetch();
                })
                .then((data) => {
                  setIsLoading(false);
                });
            });
            // }
          } catch (error) {
            console.error(error);
          }
        }}
        className="max-w-full border border-gray-300 py-1.5 text-base leading-5 font-medium text-gray-700 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500 sm:text-sm w-[70px] h-9"
      >
        {(supp.slug === 'nzz-am-sonntag' || supp.slug === "nzz-am-sonntag-2") ? (
          <>
            <option value={0}>0</option>
            <option value={1}>1</option>
          </>
        ) : (
          <>
            <option value={0}>0</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
            <option value={7}>7</option>
            <option value={8}>8</option>
            <option value={9}>9</option>
            <option value={10}>10</option>
          </>
        )}
      </select>
      <h3 className="flex-1 text-sm">
        <p className="text-gray-700 hover:text-gray-800">{supp.name}</p>
      </h3>
      <div className="text-sm font-light text-right">
        {formatMoney(supp.price)}
      </div>
    </div>
  );
};

export default UpsellProductRow;
