import React, { useEffect } from "react";
import { MinusCircleIcon, PencilIcon } from "@heroicons/react/outline";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { formatMoney, getZipCode } from "../../utils";
import { navigate } from "gatsby";
import useCart from "../../context/cart.context";
import SmallPreloader from "../SmallPreloader";
import { useMutation } from "@apollo/client";
import { VALIDATE_VOUCHER } from "../../mutations";
import CheckIcon from "../../images/vector.svg";

const Summary = ({
  cart,
  setCheckIsOpen,
  removeVoucherCode,
  updateCart,
  setIsLoading,
  isLoading,
}) => {
  const { setZipCode } = useCart();

  const filteredProducts = cart.products
    ?.filter((p) => p.product.node.__typename !== "SimpleProduct")
    .filter((p) => p.product.node.name !== "Dummy");
  const productShippingData = (product) => {
    const productShippingData = product.extraData.find(
      ({ key }) => key === "ShippingData"
    );
    return productShippingData?.value && JSON.parse(productShippingData?.value);
  };
  const simpleProducts = cart.products?.filter(
    (p) => p.product.node.__typename === "SimpleProduct"
  );
  // console.log('filteredProducts', filteredProducts[0]);
  const getNumbers = (str) => {
    return String(String(str).match(/\d+/g));
  };

  const total = parseInt(cart.total);
  return (
    <section
      aria-labelledby="summary-heading"
      className="px-8 py-6 mt-16 sm:p-2 lg:mt-0 lg:col-span-5"
    >
      <h2
        id="summary-heading"
        className="text-2xl font-medium text-gray-900 text-center font-serif border-b-[1px] border-black pb-3"
      >
        Zusammenfassung
      </h2>
      {isLoading ? (
        <SmallPreloader />
      ) : (
        <>
          <ul className="text-sm font-medium text-gray-900 divide-y divide-gray-200">
            {filteredProducts.map((product, productIdx) => {
              let streetNameField, houseNumberField, zipCodeField, cityField;
              try {
                let { streetName, city, zipCode, houseNumber } =
                  productShippingData(product);
                streetNameField = streetName;
                cityField = city;
                zipCodeField = zipCode;
                houseNumberField = houseNumber;
              } catch {
                streetNameField = "";
                cityField = "";
                zipCodeField = "";
                houseNumberField = "";
              }
              const shippingArr = [
                streetNameField,
                houseNumberField,
                zipCodeField,
                cityField,
              ];

              const productIsCoupon = product.extraData.find(
                ({ key }) => key === "isCoupon"
              );

              const voucherDeliveryMeta = product.extraData.find(
                ({ key }) => key === "voucherDeliveryMeta"
              )?.value;
              const productDeliveryPrice = product.extraData.find(
                ({ key }) => key === "bbDeliveryPrice"
              )?.value;

              const productDeliveryByPost =
                productIsCoupon &&
                voucherDeliveryMeta &&
                voucherDeliveryMeta !== "per E-Mail an mich senden";

              console.log("productDeliveryPrice", productDeliveryPrice);

              const bundledProducts = simpleProducts.filter((additional) => {
                const bundledBy = additional.extraData.find(
                  ({ key }) => key === "bundled_by"
                );
                return bundledBy?.value === product.key;
              });
              return (
                <li
                  key={productIdx}
                  className="flex items-center justify-between sm:py-6 !border-b-[1px] !border-b-black"
                >
                  <div className="flex flex-col w-full">
                    <div className="flex justify-between items-center w-full pb-2">
                      <div className="">
                        <h3 className="text-sm">
                          <a
                            href={product.href}
                            className="font-bold text-gray-800 hover:text-gray-900"
                          >
                            {product.quantity > 1 ? product.quantity : ""}{" "}
                            {product.quantity > 1 && "x"}{" "}
                            {product.extraData.find(
                              (item) => item.key === "isCoupon"
                            )
                              ? ""
                              : ""}{" "}
                            {product.product.node.name}
                          </a>
                        </h3>
                      </div>
                      <p className="mt-1 text-sm font-medium text-gray-900">
                        {formatMoney(product.product.node.price)}
                      </p>

                      <label
                        htmlFor={`quantity-${productIdx}`}
                        className="sr-only"
                      >
                        Quantity, {product.name}
                      </label>
                    </div>
                    {bundledProducts.map(
                      (bundledProduct, bundledProductIdx) => {
                        return (
                          <div
                            className="flex justify-between w-full pb-2"
                            key={bundledProductIdx}
                          >
                            <div className="">
                              <h4 className="text-sm font-normal">
                                {bundledProduct.quantity}x{" "}
                                {bundledProduct.product.node.name}
                              </h4>
                            </div>
                            <div className="">
                              <div className="flex text-sm">
                                <p className="text-gray-500">{product.color}</p>
                                {product.size ? (
                                  <p className="pl-4 ml-4 text-gray-500">
                                    {product.size}
                                  </p>
                                ) : null}
                              </div>
                              <p className="text-sm font-medium text-gray-900">
                                {formatMoney(bundledProduct.total)}
                              </p>
                            </div>
                          </div>
                        );
                      }
                    )}
                    <div className="flex mt-1 text-sm w-full">
                      <p className="text-sm font-normal">{product.color}</p>
                      {product.size ? (
                        <p className="pl-4 ml-4 text-sm font-medium text-gray-90">
                          {product.size}
                        </p>
                      ) : null}
                    </div>
                    <div>
                      {productDeliveryByPost && (
                        <div className="flex my-1 text-sm w-full justify-between">
                          <p className="text-sm font-normal">Postversand</p>
                          <p
                            className={`pl-4 ml-4 text-sm font-medium text-gray-90`}
                          >
                            {formatMoney(5)}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="flex justify-between w-full pb-2">
                      <div className="">
                        <h3 className="text-sm font-normal">
                          Lieferkosten nach {zipCodeField}
                        </h3>
                      </div>
                      <div className="">
                        <div className="flex text-sm">
                          <p className="text-gray-500">{product.color}</p>
                          {product.size ? (
                            <p className="pl-4 ml-4 text-gray-500 border-l border-gray-200">
                              {product.size}
                            </p>
                          ) : null}
                        </div>
                        <p className="text-sm font-medium text-gray-900">
                          {formatMoney(
                            Number(productDeliveryPrice?.replace(",", "."))
                          ) || formatMoney(0)}
                        </p>
                      </div>
                    </div>
                    <h3 className="text-sm font-normal mt-1">
                      Lieferadresse Brunch: {shippingArr.join(" ")}
                    </h3>
                  </div>
                </li>
              );
            })}
          </ul>
          <dl className="space-y-4 border-solid">
            <div className="flex items-center justify-between pt-[20px]">
              <dt className="text-sm text-gray-600">Zwischensumme</dt>
              <dd className="text-sm font-medium text-gray-900">
                {formatMoney(cart.subtotal)}
              </dd>
            </div>
            <div className="flex items-center justify-between">
              <dt className="flex text-sm text-gray-600">
                <span>Total inkl. MWST</span>
              </dt>
              <dd className="text-sm font-medium text-gray-900">
                {formatMoney(cart.totalTax)}
              </dd>
            </div>
            {/*<div className="items-center w-[70%] p-3 bg-[#f2f2f2]">*/}
            {/*  <label htmlFor="voucherCode" className="text-[#a9a7a7]">*/}
            {/*    Code*/}
            {/*  </label>*/}
            {/*  <div className="flex items-center justify-between">*/}
            {/*    <div className="w-full flex justify-between">*/}
            {/*      <input*/}
            {/*        type="text"*/}
            {/*        onChange={(event) => setVoucherCodeState(event.target.value)}*/}
            {/*        value={voucherCodeState}*/}
            {/*        className="customInput"*/}
            {/*      />*/}
            {/*      <button*/}
            {/*        type="button"*/}
            {/*        onClick={async () => {*/}
            {/*          const result = await checkVoucherCode({*/}
            {/*            variables: { code: voucherCodeState },*/}
            {/*          }).catch(() => undefined);*/}
            {/*          if (result?.data?.validateVoucher?.valid) {*/}
            {/*            setVoucherCodeDiscount(*/}
            {/*              getNumbers(result.data.validateVoucher.remaining_value)*/}
            {/*            );*/}
            {/*            setLastVoucherCode(voucherCodeState);*/}
            {/*            setVoucherCodeState('');*/}
            {/*            setVoucherCode('');*/}
            {/*          }*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        <div className="px-3 py-2 ml-5 bg-[#cccccc]">*/}
            {/*          <CheckIcon src="./vector.svg" alt="" />*/}
            {/*        </div>*/}
            {/*      </button>*/}
            {/*    </div>*/}
            {/*    <div className="flex justify-end">*/}
            {/*      /!*{voucherCodeDiscount && voucherCodeDiscount}*!/*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*{shippingCost && (*/}
            {/*  <>*/}
            {/*    {shippingCost.map(({ price, zipCode }) => (*/}
            {/*      <div className="flex items-center justify-between pt-4 border-t-[1px] border-black w-full">*/}
            {/*        <div className="flex items-center justify-between">*/}
            {/*          <dt className="flex items-center text-sm text-gray-600">*/}
            {/*            <dt className="text-sm text-gray-600">Lieferkosten nach</dt>*/}
            {/*            <dd className="text-sm font-medium text-gray-900">*/}
            {/*              &ensp;{zipCode}*/}
            {/*            </dd>*/}
            {/*          </dt>*/}
            {/*        </div>*/}
            {/*        <div className="flex items-center justify-between">*/}
            {/*          <dt className="flex items-center text-sm text-gray-600">*/}
            {/*            <dd className="text-sm font-medium text-gray-900">*/}
            {/*              {formatMoney(price)}*/}
            {/*            </dd>*/}
            {/*          </dt>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    ))}*/}
            {/*  </>*/}
            {/*)}*/}

            {cart.appliedCoupons?.length > 0 && (
              <div className="flex items-center justify-between pt-4 border-t-[1px] border-black">
                <dt className="flex text-sm text-gray-600">
                  <span>abzüglich Gutschein</span>
                  <a
                    href="/"
                    className="flex-shrink-0 ml-2 text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">
                      Erfahren Sie, wie sich die Steuern zusammensetzen.
                    </span>
                  </a>
                </dt>
                <dd className="text-sm font-medium text-gray-900">
                  {cart.appliedCoupons?.map((cou, index) => (
                    <div className="flex" key={index}>
                      {cou.code.toUpperCase()}{" "}
                      <MinusCircleIcon
                        className="w-5 h-5 ml-2"
                        onClick={() => removeVoucherCode(cou.code)}
                      />
                    </div>
                  ))}
                </dd>
              </div>
            )}
            {cart?.appliedCoupons?.length > 0 && (
              <div className="flex items-center justify-between">
                <dt className="flex text-sm text-red-500">
                  <span>
                    Es ist nicht möglich mit einem Gutscheincode einen
                    BrunchButler Gutschein zu erwerben.
                  </span>
                </dt>
              </div>
            )}
            <div className="flex items-center justify-between pt-4 border-t-[1px] border-black">
              <dt className="text-base font-medium text-gray-900">Total</dt>
              <dd className="text-base font-medium text-gray-900">
                {formatMoney(total)}
              </dd>
            </div>
          </dl>
        </>
      )}
      {cart.products.length > 0 && (
        <div className="mt-6 space-y-3 bg-[#f2f2f2] p-3">
          <button
            type="submit"
            className="block w-full px-4 py-3 text-base text-white border border-transparent shadow-sm bg-graniteGray hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-500 uppercase"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              navigate("/checkout");
              if (!getZipCode) {
                setCheckIsOpen(true);
              }
            }}
          >
            {cart.total == 0 ? "BESTELLEN" : "ZUR KASSE"}
          </button>
          <button
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              setZipCode(null);
              typeof window !== "undefined" &&
                localStorage.removeItem("brunchbutler_zipCode");
              navigate("/");
            }}
            className="block w-full px-4 py-3 text-base font-normal text-center text-white uppercase border border-transparent shadow-sm bg-olive hover:bg-oliveStrong focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-oliveStrong"
          >
            Weiter einkaufen
          </button>
        </div>
      )}
    </section>
  );
};

export default Summary;
