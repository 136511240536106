import React from "react";
import { formatMoney } from "../../utils";

const OldProductAdditions = ({ supp }) => {
  console.log("supp", supp);
  return (
    <div
      className={`flex flex-row items-center w-full gap-5 py-1 animate-none`}
    >
      <span className="pl-4">{supp?.quantity}</span>
      <h3 className="flex-1 text-sm">
        <p className="text-gray-700 hover:text-gray-800">{supp.product.node.name}</p>
      </h3>
      <div className="text-sm font-light text-right">
        {supp?.quantity >= 1
          ? formatMoney(
              supp?.quantity * supp.total.replace(/[^0-9.-]+/g, "")
            )
          : "CHF 0.00"}
      </div>
    </div>
  );
};

export default OldProductAdditions;
