import React, { FC } from "react";
import { TCart } from "../../types/types";

interface EmptyCartProps {
    cart: TCart
}

const EmptyCart:FC<EmptyCartProps> = ({ cart }) => {
    return (
        <>
            {cart.products.length <= 0 && (
                <div className="flex flex-col items-center justify-center py-10 space-y-5">
                    <p>Ihr Warenkorb ist leer</p>
                    <a
                        href="/"
                        className="block px-10 py-2 text-sm font-normal text-center text-white uppercase border border-transparent shadow-sm bg-olive hover:bg-oliveStrong focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-oliveStrong"
                    >
                        weiter einkaufen
                    </a>
                </div>
            )}
        </>
    );
};

export default EmptyCart;
