import { Disclosure, Transition } from "@headlessui/react";
import { PlusCircleIcon } from "@heroicons/react/outline";
import React from "react";

// interface ProductShippingAddressProps {
//   shippingData: { [key: string]: string };
//   additionalData: string;
// }

const ProductShippingAddress /*: React.FC<ProductShippingAddressProps>*/ = ({
  shippingData,
  productNote,
  productVariant,
  isVoucher,
}) => {
  const updatedProductVariant =
    productVariant && productVariant.value === "vegetarian"
      ? "Vegetarisch"
      : "Standard";
  const date = new Date(new Date(shippingData?.date));
  // change dateWithoutOneDay to format dd.mm.yyyy

  return (
    <Disclosure as="div" className="w-full px-5 py-2 my-5">
      {({ open }) => (
        <>
          <Disclosure.Button className="flex text-left lg:text-center items-center my-2 space-x-3 text-black">
            <PlusCircleIcon className="w-5 h-5" />
            <span>Angaben zu Ihrer Lieferung</span>
          </Disclosure.Button>
          <Transition
            enter="transition duration-300 ease-out origin-top"
            enterFrom="transform scale-y-0 opacity-0 "
            enterTo="transform scale-y-300 opacity-100"
            leave="transition duration-75 ease-out origin-top"
            leaveFrom="transform scale-y-100 opacity-100"
            leaveTo="transform scale-y-0 opacity-0"
          >
            <Disclosure.Panel className="my-5 space-y-3">
              <div>
                <p className="text-black/50">Lieferadresse Brunch</p>
                <p>{`${shippingData?.firstName} ${shippingData?.lastName}`}</p>
                <p>{`${shippingData?.streetName} ${shippingData?.houseNumber}`}</p>
                <p>{`${shippingData?.zipCode} ${shippingData?.city}`}</p>
                <p>{`${shippingData?.additional_information}`}</p>
                {isVoucher && shippingData?.voucherDeliveryMeta ? (
                  <p>{`${shippingData.voucherDeliveryMeta}`}</p>
                ) : (
                  <div></div>
                )}
              </div>
              <div>
                {shippingData?.date && (
                  <>
                    <p className="text-black/50">
                      Lieferdatum, Lieferzeitfenster
                    </p>
                    <p>{`${date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '.')} | ${shippingData?.time
                      .replace(" am", "")
                      .replace(" am", "")
                      .replace(" pm", "")
                      .replace(" pm", "")}`}</p>
                  </>
                )}
              </div>
              {!isVoucher && (
                <div>
                  <p className="text-black/50">Produktbemerkung</p>
                  {updatedProductVariant && <p>{`${updatedProductVariant}`}</p>}
                  {productNote?.value && (
                    <p>{`Ich bin allergisch auf: ${productNote.value}`}</p>
                  )}
                  {/*Personalisieren Sie Ihr Geschenk*/}
                </div>
              )}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

export default ProductShippingAddress;
