import React, { FC } from "react";
import useCart from "../../context/cart.context";
import { ICart } from "../../types/types";

interface RemoveButtonProps {
    additionals: any;
    setIsLoading: (isLoading: boolean) => void;
    main: any;
}

const RemoveButton: FC<RemoveButtonProps> = ({ additionals, setIsLoading, main }) => {
// @ts-ignore
  const { remove, refetch, removeCartItem, cart, setProducts } = useCart();
    const handleRemove = async () => {
      console.log();
      setIsLoading(true);
      // additionals.forEach((item: ICart) => {
      //   remove(item.key);
      // });
      await removeCartItem([main.key]).then(() => {
        refetch();
      });
      setProducts(products => products.filter((item: ICart) => item.key !== main.key));
      setIsLoading(false);
    }
    return (
        <button
            type="button"
            onClick={handleRemove}
            className="absolute flex justify-center p-2 transition-colors border-gray-400 border-solid border-[1px] rounded-full right-2 md:right-0 hover:text-white top-2 item-content hover:bg-black"
        >
            <TrashIcon />
        </button>
    );
};

const TrashIcon = () => {
    return (
        <svg className="w-3 h-3" stroke="rgb(156 163 175)" fill="rgb(156 163 175)" strokeWidth="0" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
            <path d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z" />
        </svg>
    );
};

export default RemoveButton;
