import React, { useEffect } from "react";
import { TCart, TCartItem } from '../../types/types';
import { formatMoney } from '../../utils';
import SmallPreloader from '../SmallPreloader';
import OldProductAdditions from './OldProductAdditions';
import ProductShippingAddress from './ProductShippingAddress';
import RemoveButton from './RemoveButton';
import UpsellProductRow from './UpsellProductRow';
import { gql } from "@apollo/client";
import { client } from "../../context/apollo.context";

interface CartProductItemProps {
  product: TCartItem;
  productIdx: number;
  cart: TCart;
  setSummaryLoading: (isLoading: boolean) => void;
  helpers: {
    getAdditionalProducts: (cart: TCart, product: TCartItem) => TCartItem[];
    getUpsellProducts: (
      product: TCartItem,
      additionals: TCartItem[]
    ) => TCartItem[];
    getParentKey: (product: TCartItem) => string;
  };
  isSummaryLoading: boolean;
}

const que = gql`
  query Product($slug: ID!) {
    product(id: $slug, idType: SLUG) {
      heroImage {
        heroImage {
          altText
          sourceUrl
        }
      }
      id
      name
      slug
      description
      shortDescription
      attributes {
        nodes {
          name
          options
        }
      }
      metaData {
        key
        value
      }
      image {
        sourceUrl
      }
      ... on BundleProduct {
        id
        databaseId
        name
        price
        bundleItems {
          edges {
            node {
              id
              databaseId
              image {
                sourceUrl
              }
              name
              slug
            }
            quantityMax
            quantityMin
            bundledItemId
          }
        }
      }
    }
  }
`;

const CartProductItem: React.FC<CartProductItemProps> = ({
  product,
  productIdx,
  helpers,
  cart,
  setSummaryLoading,
  isSummaryLoading,
}) => {
  const { getAdditionalProducts, getUpsellProducts, getParentKey } = helpers;
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState<any>(null);

  const additionals = getAdditionalProducts(cart, product).map((q) => ({
    ...q,
    key: q.key,
  }));
  const getDataForMainProduct = async (product: any) => {
    const { data } = await client.query({
      query: que,
      variables: { slug: product.product.node.slug },
    });
    return data;
  };
  const oldAdditionals = cart.products.filter(
    (cartProduct) =>
      getParentKey(cartProduct) !== getParentKey(product) &&
      cartProduct.product.node.__typename === 'SimpleProduct' &&
      cartProduct.product?.node?.productCategories?.nodes[0].slug ===
        'outdated-products'
  );
  const supplements = getUpsellProducts(product, additionals);
  const first3Supplements = supplements.filter((supplement, idx) => idx <= 2);

  const productIsCoupon = product.extraData.find(
    ({ key }) => key === 'isCoupon'
  );
  // const productDeliveryByPost =
  //   productIsCoupon &&
  //   product.extraData.find(({ key }) => key === 'voucherDeliveryMeta')
  //     ?.value !== 'per E-Mail an mich senden';
  const productVariant = product.extraData.find(({ key }) => key === 'Variant');
  const productNote = product.extraData.find(({ key }) => key === 'Note');
  const productShippingData = product.extraData.find(
    ({ key }) => key === 'ShippingData'
  );
  const parsedProductShipping =
    productShippingData?.value && JSON.parse(productShippingData.value);

  const setGlobalLoading = (isLoading: boolean) => {
    setIsLoading(isLoading);
    setSummaryLoading(isLoading);
  };
  // TODO: Check relationship between product and additionals
  // 1978 21,22,23
  // 1995 24,25,26
  // 1999 27,28,29
  //  236 41 1060
  useEffect(() => {
      getDataForMainProduct(product).then((data) => {
        setData(data);
      });
  } ,[data, product]);
  return (
    <li
      key={productIdx}
      className="relative flex flex-col px-8 py-10 border-t-2 border-black md:px-0 gap-5 sm:py-10"
    >
      {/* <ChangeAddress /> */}
      <RemoveButton
        setIsLoading={setGlobalLoading}
        additionals={additionals}
        main={product}
      />
      <div className="flex flex-col gap-5 w-full pt-[15px]">
        <div className="w-full flex flex-col">
          <div className="flex items-center justify-between flex-1 space-x-5 pb-5">
            <div className="flex justify-between">
              <h3 className="text-md">
                <a
                  href={product.href}
                  className="font-semibold text-gray-700 hover:text-gray-800"
                >
                  {product.product.node.name}
                </a>
              </h3>
            </div>
            <div className="flex-grow w-32 text-right">
              {formatMoney(
                (product?.quantity ?? 1) * product.product.node.price
              )}
            </div>
          </div>
          {isLoading ? (
            <SmallPreloader />
          ) : (
            <div className="flex flex-col justify-between w-full sm:ml-0">
              {(supplements.length === 3 ? supplements : first3Supplements).map(
                (supp, index) => {
                  const additionalProd = additionals.find((a) => {
                    return a.product.node.databaseId === (supp as any).databaseId;
                  })?.key;
                  return (
                    <UpsellProductRow
                      setIsLoading={setGlobalLoading}
                      isLoading={isLoading}
                      isSummaryLoading={isSummaryLoading}
                      key={index}
                      supp={supp}
                      data={data}
                      parentKey={getParentKey(product)}
                      product={product}
                      upKey={additionalProd}
                    />
                  );
                }
              )}
            </div>
          )}
        </div>
        <div>
          {product?.product?.node?.productCategories?.nodes[0].slug &&
          product?.product?.node?.productCategories?.nodes[0]?.slug ===
            'outdated-bundles' &&
          oldAdditionals?.length
            ? oldAdditionals.map((additional, index) => {
                return <OldProductAdditions key={index} supp={additional} />;
              })
            : null}
        </div>
      </div>
      <ProductShippingAddress
        isVoucher={productIsCoupon}
        shippingData={parsedProductShipping}
        productNote={productNote}
        productVariant={productVariant}
      />
    </li>
  );
};

export default CartProductItem;
